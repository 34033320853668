body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  padding: 1%;
}

.Header {
  padding-top: 10%;
  padding-bottom: 2%;
}

.Content {
  padding-top: 35px;
}

.Chart {
  padding-top: 5%;
  padding-bottom: 5%;
}

.Chart-Icon {
  padding-bottom: 20px;
}
